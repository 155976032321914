import React from "react";
import "./Hero.css";
import { enableScroll } from "../functions/scroll";
import ExternalLinks from "./ExternalLinks";

export default function Hero() {
    return (
        <>
            <div className="hero-card">
                <div id="card">
                    <h1 className="name">Dorien Pine</h1>
                        <ExternalLinks />
                        <h2 className="job-title">Full Stack/Smart Contract Developer</h2>
                    <div>
                    </div>
                </div>
                <div style={{ width: "21%" }}>
                </div>
            </div>
            <div id="container">
                <div id="scroll">
                    <h4>Scroll Down</h4>
                    <a href="#Projects" onClick={enableScroll}>
                        <i className="arrow down"></i>
                    </a>
                </div>
            </div>
        </>
    );
}




