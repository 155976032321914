import './Projects.css'
import { React, useState } from 'react';
import TechStack from './TechStack.jsx';

export default function About() {

    const [projects, setProjects] = useState({
        react: false,
        angular: false,
        node: false,
        dotnet: false,
        csharp: false,
        typescript: false,
        javascript: false,
        haskell: false,
        plutus: false,
        postgresql: false,
        mongo: false
    })


    const offClick = (e) => {
        if (e.target.className === "parallax") {
            let nodeList = document.querySelectorAll(".stack img");
            nodeList.forEach(element => {
                element.style.filter = "brightness(0.5)";
            });
        }
    }

    return (
        <section id="Projects" onClick={offClick}>
            <div children className='parallax'>
                <div className='projects-container'>
                    <h2 className="projects" >Projects</h2>
                    <div className="projects-iframe-wrapper">
                        <iframe title="projects-iframe" src="https://www.dorienpine.io" alt="ecommerce" style={{height: "100%", width: "100%"}} scrolling="no"></iframe>
                    </div>
                </div>
                <TechStack projects={projects} setProjects={setProjects} />
            </div>
        </section>
    )
}