// Get the current page scroll position
var prevScrollpos = window.pageYOffset;

const enableScroll = () => {
    document.body.style.touchAction = "auto";
}
window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
        document.getElementById("scroll").style.opacity = "100%";
    } else {
        document.getElementById("scroll").style.opacity = "0%";
        document.body.style.touchAction = "auto";
    }
    if (currentScrollPos > 0) {
        document.body.style.touchAction = "auto";
        document.getElementById("scroll").style.opacity = "0%";
    }
    if (currentScrollPos === 0) {
        document.body.style.touchAction = "none";
    }
    prevScrollpos = currentScrollPos;
}
document.body.style.touchAction = "none";
export {
    enableScroll
}