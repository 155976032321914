import './TechStack.css';
import React from "react";
// import html from '../images/html-5.png';
// import css from '../images/css-3.png';
import react from '../images/react.png';
import angular from '../images/angular.png';
import node from '../images/node.png';
import dotnet from '../images/dotnet.png';
import csharp from '../images/csharp.png';
import javascript from '../images/javascript.png';
import typescript from '../images/typescript.png';
import haskell from '../images/haskell.png';
import plutus from '../images/plutus.png';
import marlowe from '../images/marlowe.svg';
import postgresql from '../images/postgresql.png';
import mongo from '../images/mongo.png';

export default function techStack({ projects, setProjects }) {

    const flip = (e) => {
        let nodeList = document.querySelectorAll(".stack img");
        nodeList.forEach(element => {
            element.style.filter = "brightness(0.5)";
        });
        e.target.style.filter = "brightness(1)";
    }

    const presentProjects = (e) => {
        const temp = projects
        const name = e.target.id;
        temp[name] = true;
        setProjects(temp);
    }

    return (
        <div className="techstack">
            <div className='stack' onClick={(e) => { flip(e, 'react', 'angular'); presentProjects(e); }}>
                <img id='react' src={react} alt=""></img>
                <img id='angular' src={angular} alt=""></img>
            </div>
            <div className='stack' onClick={(e) => { flip(e, 'node', 'dotnet'); presentProjects(e); }}>
                <img id='node' src={node} alt=""></img>
                <img id='dotnet' src={dotnet} alt=""></img>
            </div>
            <div className='stack' onClick={(e) => { flip(e, 'csharp', 'typescript'); presentProjects(e); }}>
                <img id='csharp' src={csharp} alt=""></img>
                <img id='typescript' src={typescript} alt=""></img>
            </div>
            <div className='stack' onClick={(e) => { flip(e, 'haskell', 'plutus'); presentProjects(e); }}>
                <img id='haskell' src={haskell} alt=""></img>
                <img id='javascript' src={javascript} alt=""></img>
            </div>
            <div className='stack' onClick={(e) => { flip(e, 'postgresql', 'mongo'); presentProjects(e); }}>
                <img id='postgresql' src={postgresql} alt=""></img>
                <img id='mongo' src={mongo} alt=""></img>
            </div>
            <div className='stack' onClick={(e) => { flip(e, 'marlowe', 'aws'); presentProjects(e); }}>
                <img id='marlowe' src={marlowe} alt=""></img>
                <img id='plutus' src={plutus} alt=""></img>
            </div>
        </div>
    )
}