import './ExternalLinks.css';
import React from "react";
import github from '../images/github.png';
import linkedin from '../images/linkedin.png';

export default function ExternalLinks() {

    return (
        <div className="extlinks">
            <div className='extlink' >
                {/* <img id='html' src={html} alt=""></img>
            <img id='css' src={css} alt=""></img> */}
                <a href='https://github.com/DorienP' target="_blank" rel="noopener noreferrer">
                <img src={github} alt=""></img>
                </a>
            </div>
            <div className='extlink'>
                {/* <img id='react' src={react} alt=""></img>
            <img id='angular' src={angular} alt=""></img> */}
                <a href='https://www.linkedin.com/in/dorien-pine/' target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt=""></img>
                </a>
            </div>
        </div>
    )
}