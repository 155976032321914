import './App.css';
// import NavBar from './components/NavBar';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Particles from 'react-tsparticles';
import { particlesInit, particlesLoaded } from './functions/particles';
import './functions/scroll'

function App() {

  return (
    <main>
      {/* <NavBar /> */}
      <Hero style={{ zIndex: 100 }} />
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        className="wrapper"
        canvasClassName="canvas"
        options={{
          background: {
            color: {
              value: "#4d0621",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "grab",
                parallax: {
                  enable: true,
                  force: 110,
                  smooth: 10,
                },
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 3,
              },
              grab: {
                distance: 200,
                links: {
                  enable: true,
                  opacity: 0.5,
                  width: 2,
                }
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 5000,
              },
              value: 250,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
      <Projects />
    </main>
  );
}

export default App;
