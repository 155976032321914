import { loadFull } from "tsparticles";

const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v1 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
    let canvas = document.getElementsByClassName("canvas")
    canvas[0].style.zIndex = '';

  };

export {
    particlesInit,
    particlesLoaded
}